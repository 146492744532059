export default [
  {
    path: '/deviceMonitor',
    name: 'deviceMonitor',
    component: () => import(/* webpackChunkName: "deviceMonitor" */ './index'),
    meta: {
      type: 'deviceMonitor'
    },
    redirect: '/deviceMonitor/alertManagement',
    children: [
      {
        path: 'alertManagement',
        name: 'alertManagement',
        component: () => import(/* webpackChunkName: "deviceMonitor" */ './alertManagement')
      }
    ]
  }
]
